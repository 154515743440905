// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-author-author-js": () => import("./../../../src/components/templates/author/author.js" /* webpackChunkName: "component---src-components-templates-author-author-js" */),
  "component---src-components-templates-info-info-js": () => import("./../../../src/components/templates/info/info.js" /* webpackChunkName: "component---src-components-templates-info-info-js" */),
  "component---src-components-templates-post-post-js": () => import("./../../../src/components/templates/post/post.js" /* webpackChunkName: "component---src-components-templates-post-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-spotlights-js": () => import("./../../../src/pages/dev-spotlights.js" /* webpackChunkName: "component---src-pages-dev-spotlights-js" */),
  "component---src-pages-genres-js": () => import("./../../../src/pages/genres.js" /* webpackChunkName: "component---src-pages-genres-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-opinion-js": () => import("./../../../src/pages/opinion.js" /* webpackChunkName: "component---src-pages-opinion-js" */),
  "component---src-pages-our-picks-js": () => import("./../../../src/pages/our-picks.js" /* webpackChunkName: "component---src-pages-our-picks-js" */),
  "component---src-pages-reviews-indepth-js": () => import("./../../../src/pages/reviews/indepth.js" /* webpackChunkName: "component---src-pages-reviews-indepth-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-reviews-short-js": () => import("./../../../src/pages/reviews/short.js" /* webpackChunkName: "component---src-pages-reviews-short-js" */),
  "component---src-pages-steam-keys-js": () => import("./../../../src/pages/steam-keys.js" /* webpackChunkName: "component---src-pages-steam-keys-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */)
}

